<template>
  <div class="panel">
    <div class="container">
      <h2>{{ title }}</h2>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
  name: 'panel'
}
</script>

<style lang='less'>
.panel {
  padding: 80px 0;
  h2 {
    font-size: 32px;
    text-align: center;
    // letter-spacing: 5px;
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 2px;
      background: #e60013;
      margin: 20px auto 4px;
    }
  }
  .content {
    padding-top: 75px;
  }
  @media screen and (max-width: 992px) {
    padding: 45px 0;
    h2 {
      font-size: 24px;
    }
    .content {
      padding-top: 30px;
    }
  }
}
</style>
