// 下载文件
export function downloadFile(url, name) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
/**
* 获取Assets资源
*/
export function getAssetsFile(url) {
  return require(`@/assets/image/home/${url}`)
}
