import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/display.css'
import './styles/index.less'
import Penel from '@/components/Panel'
import { Loading, OptionGroup, MessageBox, Input, Select, Option, Upload, Button, FormItem, Form, Dialog, Collapse, CollapseItem, Dropdown, DropdownMenu, DropdownItem, Popover, Drawer, Menu, Submenu, MenuItemGroup, MenuItem, Tabs, TabPane, Table, TableColumn, Breadcrumb, BreadcrumbItem, Row, Col } from 'element-ui'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    region: 'en',
    language: 'en',
    // key: 'AIzaSyCqYYsej7eH93tttLfNRLRQjOgOmjndC0I',
    key: 'AIzaSyAQEBkdUVb1iWYUtVGDeV7D9e6AMahqcKA',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  }
  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,
  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
})

Vue.component( 'Penel', Penel )
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use( VueI18n )
Vue.use( Dropdown )
Vue.use( DropdownMenu )
Vue.use( DropdownItem )
Vue.use( Popover )
Vue.use( Drawer )
Vue.use( Menu )
Vue.use( Submenu )
Vue.use( MenuItemGroup )
Vue.use( MenuItem )
Vue.use( Collapse )
Vue.use( CollapseItem )
Vue.use( Dialog )
Vue.use( Form )
Vue.use( FormItem )
Vue.use( Button )
Vue.use( Input )
Vue.use( Select )
Vue.use( Option )
Vue.use( OptionGroup )
Vue.use( Tabs )
Vue.use( TabPane )
Vue.use( Table )
Vue.use( TableColumn )
Vue.use( Breadcrumb )
Vue.use( BreadcrumbItem )
Vue.use( Loading )
Vue.use( Row )
Vue.use( Col )
Vue.use( Upload )

Vue.prototype.$messageBox = MessageBox
function getLanguage() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  var locale = localStorage.getItem('locale') || 'en'
  if (/android/i.test(userAgent)) {
    const navigatorLocale = navigator?.language.split('-')[0]
    if (navigatorLocale === locale) {
      return navigatorLocale
    } else {
      return locale
    }
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const navigatorLocale = navigator?.language.split('-')[0]
    if (navigatorLocale === locale) {
      return navigatorLocale
    } else {
      return locale
    }
  } else {
    return locale
  }
}
// const locale = localStorage.getItem( 'locale' ) || 'en'
const i18n = new VueI18n( {
  locale: getLanguage(),
  // defaultLocale: 'en',
  messages: {
    en: require( '@/locales/en.json' ),
    zh: require( '@/locales/zh.json' ),
    kh: require( '@/locales/kh.json' )
  }
} )
Vue.config.productionTip = false
Vue.config.devtools = true

new Vue( {
  router,
  i18n,
  mounted () {
    document.dispatchEvent( new Event( 'render-event' ) )
  },
  render: h => h( App )
} ).$mount( '#app' )
